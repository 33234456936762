import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  caseCard: GatsbyTypes.ContentfulCase;
};

export const IndexCaseCard: React.FC<Props> = ({ caseCard }) => {
  return (
    <>
      <article className="caseCard">
        <Link to={`/cases/${caseCard.contentful_id}`}>
          {caseCard?.ogpImage?.gatsbyImageData && (
            <div className="caseCardImage">
              <GatsbyImage image={caseCard.ogpImage.gatsbyImageData} alt="" />
            </div>
          )}
          <div className="caseCardBody">
            <p className="caseCardDescription">{caseCard.title}</p>
            <h2 className="caseCardCompanyName">{caseCard.company?.name}</h2>
          </div>
        </Link>
      </article>
      <style jsx>{`
        .caseCard {
          height: 100%;
        }
        .caseCard :global(a) {
          background-color: ${colors.white};
          border-radius: 20px;
          box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
          display: block;
          height: 100%;
          overflow: hidden;
          transition: all 0.2s ease-out;
        }
        .caseCard :global(a:hover) {
          filter: brightness(105%);
          transform: translateY(-2px);
        }
        .caseCardImage {
          border-radius: 20px 20px 0 0;
          width: 100%;
        }
        .caseCardBody {
          color: ${colors.defaultText};
          padding: 15px 20px 30px;
        }
        .caseCardDescription {
          font-size: 15px;
          line-height: 1.75;
        }
        .caseCardCompanyName {
          font-size: 15px;
          font-weight: 500;
          margin: 20px 0 0;
        }
        @media (${breakPoints.sp}) {
          .caseCard :global(a:hover) {
            filter: brightness(100%);
            transform: translateY(0px);
          }
          .caseCardImage img {
            border-radius: 20px 20px 0 0;
          }
          .caseCardBody {
            padding: 20px 15px 30px;
            min-height: 100%;
          }
          .caseCardDescription {
            font-size: 13px;
          }
          .caseCardCompanyName {
            font-size: 13px;
          }
          .caseCardImage {
            max-height: 400px;
            object-fit: cover;
          }
        }
      `}</style>
    </>
  );
};
