import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints, siteWidth } from "~/utils/variables";

export const Hero: React.FC = () => {
  const { firstViewPng, firstViewMarkPng } = useStaticQuery<GatsbyTypes.firstViewImageQuery>(graphql`
    query firstViewImage {
      firstViewPng: file(relativePath: { eq: "firstView.png" }) {
        childImageSharp {
          gatsbyImageData(width: 700, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      firstViewMarkPng: file(relativePath: { eq: "researchBadge.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <div id="hero" className="hero">
        <div className="container">
          <div className="leftContainer">
            <h1 className="title">
              リファラル採用で
              <br />
              採用を強くする
            </h1>
            <p className="description">
              リファラル採用を科学し、
              <br />
              成功に並走するサービス「Refcome」
            </p>
            <div className="buttonWrapper pcVisible">
              <CallToActionButton
                to={PATH.INQUIRY_CONTACT.to}
                backgroundColor={colors.brandGradient}
                textColor={colors.white}
                size={`200px`}
              >
                資料請求する
              </CallToActionButton>
            </div>
          </div>
          <div className="rightContainer">
            {firstViewPng?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage image={firstViewPng?.childImageSharp?.gatsbyImageData} alt="" />
            )}
            <div className="markBlock">
              <p className="markDescription">
                <span>※日本トレンドリサーチによるインターネット調査</span>
                <span>(2022年1月_サイトイメージ調査)</span>
              </p>
              {firstViewMarkPng?.childImageSharp?.gatsbyImageData && (
                <div className="researchBadge">
                  <GatsbyImage
                    image={firstViewMarkPng?.childImageSharp?.gatsbyImageData}
                    alt="日本トレンドリサーチによるインターネット調査"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="buttonWrapper spVisible">
          <CallToActionButton
            to={PATH.INQUIRY_CONTACT.to}
            backgroundColor={colors.brandGradient}
            textColor={colors.white}
            size={`300px`}
          >
            資料請求する
          </CallToActionButton>
        </div>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          margin: 0 auto;
        }
        .title {
          line-height: 1.5;
        }
        .description {
          color: ${colors.lightText};
          line-height: 1.6;
        }
        .buttonWrapper :global(a) {
          display: block;
          letter-spacing: 0.075em;
          text-align: center;
          padding: 15px 30px;
        }
        .markBlock {
          position: absolute;
          display: flex;
          right: 0;
          bottom: 5px;
          text-align: right;
          align-items: flex-end;
        }
        .markDescription {
          color: ${colors.lightText};
          font-size: 10px;
          line-height: 1.4;
          margin-right: 10px;
        }
        .markDescription:first-of-type {
          margin-bottom: 5px;
        }
        @media (${breakPoints.pc}) {
          .hero {
            margin-top: 92px;
            padding: 80px 30px 100px;
          }
          .container {
            flex-direction: row;
            align-items: start;
            max-width: ${siteWidth.default};
            position: relative;
          }
          .leftContainer {
            width: 100%;
          }
          .rightContainer {
            position: absolute;
            left: 323px;
            top: -3.88px;
          }
          .title {
            font-size: 53px;
          }
          .description {
            margin: 30px 0;
            font-size: 24px;
          }
          .buttonWrapper.pcVisible :global(a) {
            font-size: 20px;
            padding: 16px 0;
          }
        }
        @media (${breakPoints.tablet}) {
          .container {
            position: relative;
            margin-top: 86px;
          }
          .leftContainer {
            width: 100%;
            padding: 30px 50px 60px;
          }
          .rightContainer {
            position: absolute;
            width: 450px;
            right: 10%;
          }
          .markBlock {
            bottom: -20px;
          }
          .description {
            margin: 20px 0;
          }
        }

        @media (${breakPoints.sp}) {
          .hero {
            margin-top: 72px;
            padding: 0 30px 50px;
          }
          .container {
            flex-direction: column;
            align-items: center;
            width: 100%;
            min-height: 400px;
          }
          .leftContainer {
            width: 100%;
            padding: 0;
          }
          .rightContainer {
            width: 100%;
            position: static;
            padding-bottom: 30px;
          }
          .markBlock {
            bottom: -10px;
          }
          .markDescription {
            width: calc(100% - 70px);
          }
          .markDescription span {
            display: block;
          }
          .researchBadge {
            width: 70px;
          }
          .title {
            font-size: 34px;
          }
          .description {
            font-size: 19px;
            margin: 10px 0 30px;
          }
          .buttonWrapper {
            margin-top: 40px;
          }
          .buttonWrapper.spVisible {
            display: flex;
            justify-content: center;
          }
          .buttonWrapper.spVisible :global(a) {
            font-size: 20px;
          }
        }
      `}</style>
    </>
  );
};
