import React from "react";
import { Container } from "~/atoms/Container";
import { useStaticQuery, graphql, Link } from "gatsby";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export const AskUs = () => {
  const { askUsPng } = useStaticQuery<GatsbyTypes.askUsImageQuery>(graphql`
    query askUsImage {
      askUsPng: file(relativePath: { eq: "askUs.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .wrapper :global(.container) {
          background: url(${askUsPng?.childImageSharp?.gatsbyImageData?.images?.fallback?.src});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          position: relative;
          text-align: center;
          z-index: 0;
        }
        .wrapper :global(.container):before {
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          content: "";
          background: rgba(38, 22, 6, 0.8);
          z-index: 1;
          top: 0;
          left: 0;
        }
        .contentWrapper {
          position: relative;
          z-index: 2;
        }
        .title {
          color: ${colors.white};
          margin: 0 0 20px;
        }
        .text {
          color: ${colors.white};
          line-height: 1.5;
        }
        .buttonContainer {
          display: flex;
          justify-content: center;
          margin: 40px auto 0;
          padding: 0 20px;
        }
        .buttonContainer :global(a) {
          background-image: ${colors.brandGradient};
          background-color: ${colors.brand};
          border-radius: 50px;
          color: ${colors.white};
          display: block;
          font-size: 15px;
          letter-spacing: 0.075em;
          font-weight: 500;
          line-height: 1;
          text-align: center;
          text-decoration: none;
          transition: all 0.2s ease-out;
          padding: 21px 0;
          min-width: 300px;
        }
        .buttonContainer :global(a:hover) {
          background-image: ${colors.brandGradient};
          background-color: ${colors.brand};
          opacity: 0.85;
          text-decoration: none;
          transform: translate3d(0, -1px, 0);
        }
        @media (${breakPoints.pc}) {
          .wrapper {
            margin: 100px 0 0;
            padding: 0 30px;
          }
          .wrapper :global(.container) {
            border-radius: 20px;
            padding: 80px 0;
          }
          .wrapper :global(.container):before {
            border-radius: 20px;
          }
          .title {
            font-size: 32px;
          }
          .text {
            font-size: 20px;
          }
          .buttonContainer :global(a) {
            font-size: 18px;
          }
        }
        @media (${breakPoints.tablet}) {
          .contentWrapper {
            padding: 60px 0;
          }
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            margin: 50px 0 0;
          }
          .wrapper :global(.container) {
            padding: 50px 0;
          }
          .title {
            font-size: 20px;
          }
          .text {
            font-size: 15px;
          }
          .buttonContainer :global(a) {
            font-size: 16px;
          }
        }
      `}</style>
      <div className="wrapper">
        <Container>
          <div className="contentWrapper">
            <h2 className="title">
              <span>リフカムに</span>
              <span>相談してみませんか？</span>
            </h2>
            <p className="text">
              <span>リファラル採用に関するご質問、</span>
              <span>ご相談など気軽にお問い合わせください</span>
            </p>
            <div className="buttonContainer">
              <Link to={PATH.INQUIRY_CONTACT.to}>資料請求・お問い合わせ</Link>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
