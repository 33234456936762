import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SectionHeading } from "~/atoms/IndexPageSectionHeading";
import { Container } from "~/atoms/Container";
import { Feature } from "~/atoms/IndexPageFeature";
import { breakPoints } from "~/utils/variables";

export const Features: React.FC<{}> = () => {
  const { feature1, feature2, feature3, feature4 } = useStaticQuery<GatsbyTypes.featureImageQuery>(graphql`
    query featureImage {
      feature1: file(relativePath: { eq: "feature1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature2: file(relativePath: { eq: "feature2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature3: file(relativePath: { eq: "feature3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
      feature4: file(relativePath: { eq: "feature4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .wrapper {
          padding: 120px 30px 0;
        }
        .wrapper :global(.container) {
          flex-direction: column;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 50px 0 0;
          }
          .wrapper :global(.container) {
            padding: 0;
          }
        }
        @media (${breakPoints.pc}) {
          .features :global(.wrapper:first-child) {
            margin: 50px 0 0;
          }
          .features :global(.wrapper:last-child) {
            margin: 0;
          }
        }
      `}</style>
      <div className="wrapper">
        <Container>
          <SectionHeading
            main={
              <>
                <span>リファラル採用における</span>
                <span>
                  <span className="metricResize">Refcome</span>の強み
                </span>
              </>
            }
            sub={
              <>
                <span>Refcomeはリファラル採用を</span>
                <span>成功に導く「伴走者」です</span>
              </>
            }
          />
          <div className="features">
            <Feature
              subTitle="ANALYZABLE"
              title={
                <span>
                  リファラル採用の活動量と
                  <br />
                  定着度を見える化
                </span>
              }
              description="リファラル採用の活性化には、定量的な分析を基にした改善が不可欠です。Refcomeでは制度の周知状況や、部門・社員ごとの活動量を可視化し、データに基づいてボトルネックを把握。次なる改善案を導きます。"
              imageData={feature1?.childImageSharp?.gatsbyImageData}
            />
            <Feature
              subTitle="EASY"
              title={
                <span>
                  人事にも社員にも
                  <br />
                  わかりやすい紹介フロー
                </span>
              }
              description="煩雑な紹介手続きをスッキリ整理。メールやアプリに届く専用ページを、友人に転送するだけで紹介完了。人事からアプリ通知で紹介を依頼できるので、多店舗のアルバイトスタッフにも手軽に周知できます。"
              imageData={feature2?.childImageSharp?.gatsbyImageData}
              direction="ltr"
            />
            <Feature
              subTitle="PRIVATE"
              title={
                <span>
                  なぜ紹介が発生しないのか？
                  <br />
                  社員の心理を把握できます
                </span>
              }
              description="リファラル採用を成功させる鍵は会社への愛着や満足度です。Refcomeでは、リファラルの障壁となりうる社員の心理的な要因やエンゲージメントを把握することで、貴社に最適なリファラルの設計を実現できます。"
              imageData={feature3?.childImageSharp?.gatsbyImageData}
            />
            <Feature
              subTitle="PROFESSIONAL"
              title={
                <span>
                  経験豊富な
                  <br />
                  アドバイザーが支援します
                </span>
              }
              description="人事経験者や人材業界出身のアドバイザーが、850社のリファラル採用支援で蓄積したノウハウをもとに、組織の課題や状況に合わせてリファラル採用を成功に導く支援を行います。"
              imageData={feature4?.childImageSharp?.gatsbyImageData}
              direction="ltr"
            />
          </div>
        </Container>
      </div>
    </>
  );
};
