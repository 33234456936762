import React from "react";
import { Link } from "gatsby";
import { colors } from "~/utils/colors";
import { ExternalLink } from "~/atoms/ExternalLink";

interface ButtonProps {
  children: React.ReactNode;
  backgroundColor?: string;
  color?: string;
  textColor?: string;
  fill?: boolean;
  gradient?: boolean;
  size?: string;
  to: string;
  external?: boolean;
}

export const CallToActionButton: React.FC<ButtonProps> = ({
  children,
  backgroundColor,
  color,
  textColor,
  fill,
  gradient = true,
  external = false,
  size,
  to,
}) => {
  const baseColor = color || colors.defaultText;

  return (
    <>
      <style jsx>{`
        .callToActionButton {
          display: block;
          position: relative;
        }
        .callToActionButton :global(a) {
          align-items: center;
          background-image: ${colors.brandGradient};
          background-color: ${colors.brand};
          border-radius: 50px;
          display: flex;
          font-size: 15px;
          font-weight: 500;
          line-height: 28px;
          justify-content: center;
          padding: 12px 10px;
          position: relative;
          text-align: center;
          text-decoration: none;
          transition: all 0.2s ease-out;
          width: ${size || `140px`};
          z-index: 1;
        }
        .callToActionButton.gradient :global(a:hover) {
          background-image: ${colors.brandGradient};
          background-color: ${colors.brand};
          opacity: 0.85;
          text-decoration: none;
          transform: translate3d(0, -1px, 0);
        }
        .callToActionButton.gradient :global(a:before) {
          background-color: ${backgroundColor || colors.white};
          border-radius: 50px;
          content: "";
          left: 1px;
          height: calc(100% - 2px);
          position: absolute;
          top: 1px;
          width: calc(100% - 2px);
          z-index: 0;
        }
        .callToActionButtonText {
          color: ${textColor || colors.brand};
          display: block;
          position: relative;
          z-index: 1;
        }
        .callToActionButton.default {
          background: ${(fill && baseColor) || `transparent`};
          border: solid 1px ${baseColor};
          color: ${fill && colors.white};
          padding: 12px 10px;
        }
      `}</style>
      <span className={`callToActionButton ${(gradient && `gradient`) || `default`}`}>
        {external ? (
          <ExternalLink to={to}>
            <span className="callToActionButtonText">{children}</span>
          </ExternalLink>
        ) : (
          <Link to={to}>
            <span className="callToActionButtonText">{children}</span>
          </Link>
        )}
      </span>
    </>
  );
};
