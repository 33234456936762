import React, { ReactNode } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

type Props = {
  subTitle: string;
  title: ReactNode;
  description: string;
  imageData?: IGatsbyImageData;
  direction?: "ltr" | "rtl";
};

export const Feature: React.FC<Props> = ({ direction = "rtl", title, subTitle, description, imageData }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          align-items: center;
          display: flex;
          flex-direction: ${direction === "rtl" ? "row-reverse" : "row"};
          margin: 100px auto;
        }
        .imageWrapper {
          margin: 0 0 0 ${direction === "rtl" ? "10%" : 0};
          width: 45%;
        }
        .textWrapper {
          margin: 0 0 0 ${direction === "ltr" ? "10%" : 0};
          width: 45%;
        }
        .subTitle {
          color: ${colors.brand};
          display: flex;
          font-size: 17px;
          font-weight: 600;
          letter-spacing: 0.1em;
          line-height: 1;
          position: relative;
          padding: 0 0 0 20px;
        }
        .subTitle:before {
          background-color: ${colors.brand};
          border-radius: 50%;
          content: "";
          height: 10px;
          left: 0;
          position: absolute;
          top: 4px;
          width: 10px;
        }
        .title {
          font-size: 30px;
          font-weight: 500;
          line-height: 1.5;
          margin: 20px 0;
        }
        .description {
          color: ${colors.lightText};
          font-size: 15px;
          line-height: 1.8;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            align-items: flex-start;
            flex-direction: column;
            margin: 0 0 50px;
            max-width: 100%;
          }
          .imageWrapper {
            margin: 0 auto;
            padding: 0 30px;
            width: 90%;
          }
          .textWrapper {
            margin: 0;
            padding: 20px 30px;
            width: 100%;
          }
          .subTitle {
            font-size: 15px;
          }
          .title {
            font-size: 20px;
            margin: 10px 0;
          }
        }
      `}</style>
      <div className="wrapper">
        {imageData && (
          <div className="imageWrapper">
            <GatsbyImage image={imageData} alt="" />
          </div>
        )}
        <div className="textWrapper">
          <div className="subTitle">{subTitle}</div>
          <h2 className="title">{title}</h2>
          <p className="description">{description}</p>
        </div>
      </div>
    </>
  );
};
