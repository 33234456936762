import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { Hero } from "../molecules/IndexPageHero";
import { Question } from "~/molecules/IndexPageQuestion";
import { Features } from "~/molecules/IndexPageFeatures";
import { CasesOnTop } from "~/molecules/IndexPageCases";
import { AskUs } from "~/molecules/IndexPageAskUs";

type Props = {
  data: {
    caseEbara: GatsbyTypes.ContentfulCase;
    caseTiis: GatsbyTypes.ContentfulCase;
    caseFreee: GatsbyTypes.ContentfulCase;
  };
};

const IndexPage: React.FC<Props> = ({ data }) => {
  const { caseEbara, caseTiis, caseFreee } = data;

  return (
    <Layout isTopPage title="口コミ人気No.1">
      <Hero />
      <Question />
      <Features />
      <CasesOnTop caseCards={[caseEbara, caseTiis, caseFreee]} />
      <AskUs />
    </Layout>
  );
};

export default IndexPage;

export const IndexPageCaseQuery = graphql`
  query IndexPageCase {
    caseEbara: contentfulCase(contentful_id: { eq: "4b14G6HYbPApTAZT22orHR" }) {
      id
      contentful_id
      title
      company {
        id
        name
      }
      ogpImage {
        gatsbyImageData(aspectRatio: 1.618, width: 600)
      }
    }
    caseTiis: contentfulCase(contentful_id: { eq: "1lj3JPMO4VRlef5X8OrfX7" }) {
      id
      contentful_id
      title
      company {
        id
        name
      }
      ogpImage {
        gatsbyImageData(aspectRatio: 1.618, width: 600)
      }
    }
    caseFreee: contentfulCase(contentful_id: { eq: "5swfIV0c3WWN9bW0wWGsEa" }) {
      id
      contentful_id
      title
      company {
        id
        name
      }
      ogpImage {
        gatsbyImageData(aspectRatio: 1.618, width: 600)
      }
    }
  }
`;
