import React from "react";
import { Container } from "~/atoms/Container";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

export const Question: React.FC<{}> = () => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          background: ${colors.lightOrange};
          padding: 70px 30px;
        }
        .wrapper :global(.container) {
          flex-direction: column;
        }
        .heading {
          font-size: 32px;
          line-height: 1.7;
          margin: 0 0 30px;
          text-align: center;
        }
        .paragraph {
          font-size: 15px;
          line-height: 1.7;
          word-wrap: break-word;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 50px 30px;
          }
          .heading {
            font-size: 24px;
          }
          .paragraph {
            font-size: 14px;
          }
          .spBreak {
            display: block;
          }
          .spBreak :global(span) {
            display: inline;
          }
        }
        @media (${breakPoints.pc}) {
          .paragraph {
            text-align: center;
          }
        }
      `}</style>
      <div id="question" className="wrapper">
        <Container>
          <h2 className="heading">
            <span>リファラル採用の推進に、</span>
            <span>苦労していませんか？</span>
          </h2>
          <p className="paragraph">
            <span className="spBreak">
              <span>リファラル採用は、社員の大切な友人・知人を</span>
              <span>紹介してもらうことで選考を行う手法です。</span>
            </span>
            <span className="spBreak">
              <span>他の手法に比べ、内定率や定着率の高さ、早期戦力化、</span>
              <span>採用コスト削減など、多くのメリットがあります。</span>
            </span>
            <span className="spBreak">
              <span>企業文化にあった制度設計や全社的な協力者の巻き込みなど、</span>
              <span>粘り強く取り組むことが成功の決め手です。</span>
            </span>
          </p>
        </Container>
      </div>
    </>
  );
};
