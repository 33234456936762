import React from "react";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { SectionHeading } from "~/atoms/IndexPageSectionHeading";
import { CompanyLogos } from "~/molecules/CompanyLogos";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints, siteWidth } from "~/utils/variables";
import { IndexCaseCard } from "~/molecules/IndexCaseCard";

type Props = {
  caseCards: GatsbyTypes.ContentfulCase[];
};

export const CasesOnTop: React.FC<Props> = ({ caseCards }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${colors.white};
          padding: 0 30px;
        }
        .cases {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          max-width: ${siteWidth.default};
          padding: 120px 0 0;
        }
        .cards {
          display: flex;
          width: 100%;
          height: 100%;
          margin-bottom: 40px;
        }
        .card {
          width: calc((100% / 3) - 30px);
          margin: 0 15px;
        }
        .buttonWrapper {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .buttonWrapper :global(.callToActionButton.gradient a) {
          padding: 15px;
          width: 250px;
        }
        .companyLogos {
          margin: 70px auto;
        }
        @media (${breakPoints.sp}) {
          .cases {
            padding: 30px 0 0;
          }
          .casesOnTop {
            padding: 0;
          }
          .casesOnTop :global(.container) {
            padding: 50px 0 0;
          }
          .cardsWrapper {
            overflow-x: scroll;
            overflow-y: hidden;
            width: 100%;
          }
          .cards {
            margin-bottom: 30px;
            padding: 0 20px;
            width: 240%;
            max-width: 1000px;
            justify-content: space-between;
          }
          .cards::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
          }
          .buttonWrapper :global(.callToActionButton.gradient a) {
            padding: 15px;
            width: 300px;
          }
          .companyLogos {
            margin: 50px 0 0;
            padding: 0;
          }
        }
      `}</style>
      <div className="wrapper">
        <div className="cases">
          <SectionHeading
            main={<span>導入事例</span>}
            sub={
              <>
                <span>多くの企業がリファラル採用の</span>
                <span>定着に成功しています</span>
              </>
            }
          />
          <div className="cardsWrapper">
            <div className="cards">
              {caseCards.map((caseCard) => (
                <div key={caseCard.id} className="card">
                  <IndexCaseCard caseCard={caseCard} />
                </div>
              ))}
            </div>
          </div>
          <div className="buttonWrapper">
            <CallToActionButton to={PATH.CASES.to} size={`100%`}>
              導入事例一覧をみる
            </CallToActionButton>
          </div>
          <div className="companyLogos">
            <CompanyLogos />
          </div>
        </div>
      </div>
    </>
  );
};
